import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import {  of } from 'rxjs';
import { merge } from 'lodash';


@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

    constructor(
        private httpClient: HttpClient,
        ) { }

  getTranslation(lang: string): Observable<any> {
    const cacheBuster = 'cb=' + new Date().getTime();
    const defaultJsonPath = `./assets/i18n/default/${lang}.json?${cacheBuster}`;
  
    if (this.userHasCustomTranslation()) {
      return this.getCustomTranslation(lang, defaultJsonPath);
    } else {
      return this.httpClient.get(defaultJsonPath).pipe(
        catchError(_ => this.httpClient.get(`./assets/i18n/default/en.json`))
      );
    }
  }
  
  private getCustomTranslation(lang: string, defaultJsonPath: string): Observable<any> {
    const userCustomer = localStorage.getItem('currentCustomer');
    const cacheBuster = 'cb=' + new Date().getTime();
    const customJsonPath = `./assets/i18n/c${userCustomer}/${lang}.json?${cacheBuster}`;
  
    return this.httpClient.get(customJsonPath).pipe(
      catchError(() => of({})),
      mergeMap(customTranslations => this.mergeTranslations(defaultJsonPath, customTranslations))
    );
  }

  private mergeTranslations(defaultJsonPath: string, customTranslations: any): Observable<any> {
    if (Object.keys(customTranslations).length > 0) {
      return this.httpClient.get(defaultJsonPath).pipe(
        catchError(() => of({})),
        map(defaultTranslations => merge({}, defaultTranslations, customTranslations))
      );
    } else {
      return this.httpClient.get(defaultJsonPath).pipe(
        catchError(() => of({}))
      );
    }
  }
  
  userHasCustomTranslation(): boolean {
      const userCustomer = localStorage.getItem('currentCustomer');
      const customersWithCustomTranslations = environment.customersWithCustomTranslation.split(',').map(a => Number(a));
      return customersWithCustomTranslations.find(c => c === Number(userCustomer)) ? true : false;
  }

}